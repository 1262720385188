import 'codemirror/lib/codemirror.css';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/monokai.css';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/addon/edit/closebrackets';
import './index.css';
import { Controlled as CodeMirror } from 'react-codemirror2';
import React from 'react';



function Editor({ code, setCode }) {
    return <CodeMirror
        value={code}
        options={{
            mode: "text/x-c++src",
            theme: 'monokai',
            keyMap: 'sublime',
            lineNumbers: true,
            autoCloseBrackets: true,
            dragDrop: false,
            indentUnit: 2,
            tabSize: 2
        }}
        onBeforeChange={(editor, data, value) => {
            setCode(value);
        }}
    />
}

export default Editor;