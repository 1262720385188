import React from 'react';
import { Dialog, Classes } from '@blueprintjs/core';

import './index.css';

function About(props) {
    return <Dialog
        className="bp3-dark"
        icon="help"
        isOpen={props.show}
        canOutsideClickClose={true}
        onClose={() => props.toggle(false)}
        title="About AleOJ"
    >
        <div className={Classes.DIALOG_BODY}>
            <p>
                <strong>What?</strong>
            </p>
            <p>
                A Free Online Judger that you could use to practice "data structures and algorithms".
            </p>

            <p>
                <strong>Why?</strong>
            </p>
            <p>
                Simple, fast and highly customizable, yet another hobby project.
            </p>

            <p>
                <strong>How?</strong>
            </p>
            <p>
                This project is built from scratch by <a href="http://liang.fi" target="_blank" rel="noreferrer">liang.fi</a>. The frontend is written in "React" while the "Judger core" and "backend" is written in Go.

                Available docker image for the <a href="https://hub.docker.com/repository/docker/liangular/aleoj" target="_blank" rel="noreferrer">Judger Core</a>.
            </p>
        </div>
    </Dialog>
}

export default About;