import React, { useState, useEffect } from 'react';
import { Callout, Icon } from '@blueprintjs/core';
import axios from 'axios';
import { API_URL } from '../../../config';
import './index.css';

const statusMap = {
    "TLE": "Time Limit Exceeded",
    "MLE": "Memory Limit Exceeded",
    "RE": "Runtime Error",
    "WA": "Wrong Answer",
    "ACE": "Accepted",
    "ERR": "Error"
}

function Submission({ result, problemId }) {
    const [solutions, setSolutions] = useState([])

    useEffect(() => {
        const fetchSolutions = async () => {
            const result = await axios(
                `${API_URL}/solutions?problem_id=${problemId}&page=1&per=10`,
            );
            setSolutions(result.data);
        };

        fetchSolutions();
    }, [problemId]);

    const isCorrect = result.status === 'ACE';
    const isWA = result.status === 'WA';
    return <div>
        {result.status && <Callout intent={isCorrect ? 'success' : 'danger'} icon={isCorrect ? 'tick' : 'error'} title={statusMap[result.status]} className="bp3-dark submission-result" >
            {result.error && <code className="submission-code">{result.error}</code>}
            {result.time && <div>Runtime: <strong>{result.time} ms </strong> </div>}
            {result.memory && <div>Memory Usage: <strong>{Math.ceil(result.memory / 1024)} KB </strong></div>}
            {
                isWA && < div >
                    <code className="submission-code">Case: {result.case}</code>
                    <code className="submission-code">Expect: {result.expect}</code>
                    <code className="submission-code">Got: {result.actual}</code>
                </div>
            }
        </Callout>
        }
        <br />

        {solutions.length && <table className="aleoj-submission-table bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-dark">
            <thead>
                <tr>
                    <th>Time Submitted</th>
                    <th>Status</th>
                    <th>Runtime</th>
                    <th>Memory</th>
                    <th>Language</th>
                    <th>Author</th>
                </tr>
            </thead>
            <tbody>
                {solutions.map(solution => <tr key={solution.id}>
                    <td>{(new Date(solution.timestamp)).toLocaleDateString()}</td>
                    <td>
                        {solution.status === 'ACE' ? <Icon icon="tick" intent="success" /> : <Icon icon="error" intent="danger" />}
                        {" "}{statusMap[solution.status]}
                    </td>
                    <td>{solution.time} ms</td>
                    <td>{Math.ceil(solution.memory / 1024)} KB</td>
                    <td>{solution.language}</td>
                    <td>{solution.author}</td>
                </tr>)}
            </tbody>
        </table>}
        <div className="aleoj-submission-notes">only show last 10 submissions</div>
    </div >
}

export default Submission;