import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../../config';
import './index.css';

function Problems({ close, onChoose }) {
    const [problems, setProblems] = useState([])
    const history = useHistory();

    const fetchData = async () => {
        const result = await axios(
            `${API_URL}/problems?page=1&per=1000`,
        );
        setProblems(result.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <table className="aleoj-problems-table bp3-html-table bp3-html-table-bordered bp3-interactive bp3-html-table-striped">
        <tbody>
            {problems.data && problems.data.map(problem => <tr key={problem.id} onClick={() => {
                history.push(`/problems/${problem.id}`)
                onChoose(problem.id);
                close();
            }}>
                <td className="id">#{problem.id}</td>
                <td>{problem.title}</td>
                <td className="tag">
                    <span className="bp3-tag bp3-round bp3-intent-success bp3-minimal">{problem.tag}</span>
                </td>
            </tr>)}
        </tbody>
    </table>
}

export default Problems;