import React from 'react';
import { Navbar, Alignment, Button } from '@blueprintjs/core';

function Footer({ onProblemsClick, onSubmitClick, isSubmiting }) {
    return <Navbar className="bp3-dark aleoj-footer">
        <Navbar.Group align={Alignment.LEFT}>
            <Button className="bp3-button bp3-intent-success bp3-minimal" icon="list-detail-view" text="Problems" onClick={onProblemsClick} />
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
            <Navbar.Divider />
            <Button loading={isSubmiting} disabled={isSubmiting} className="bp3-button bp3-intent-success bp3-minimal" icon="saved" text="Submit" onClick={onSubmitClick} />
        </Navbar.Group>
    </Navbar>;
}

export default Footer;