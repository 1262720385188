import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AleojView from './views/Aleoj';

class App extends React.Component {
  render() {
    return <Router>
      <Switch>
        <Route exact path="/">
          <AleojView />
        </Route>
        <Route path="/problems/:id">
          <AleojView />
        </Route>
        <Route path="/solutions/:id">
          <h1>solutions</h1>
        </Route>
      </Switch>
    </Router>


  }
}

export default App;
