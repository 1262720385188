import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Callout } from '@blueprintjs/core';
import axios from 'axios';
import { Spinner } from '@blueprintjs/core';
import { API_URL } from '../../../config';
import gfm from 'remark-gfm';
import './index.css';

function Description({ problemId }) {
    const [problem, setProblem] = useState(null);
    const [err, setErr] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios(
                    `${API_URL}/problems/${problemId}`,
                );
                setProblem(result.data);
            } catch (e) {
                setErr(e.message);
            }

        };

        fetchData();
    }, [problemId])

    if (err != null) {
        return <Callout intent="danger" icon="error" title={err} className="bp3-dark" />
    }

    return problem ?
        <ReactMarkdown plugins={[gfm]} children={problem.description} />
        : <Spinner />

}

export default Description;