import './index.css';
import React, { useState } from 'react'
import { Tabs, Tab, Drawer } from '@blueprintjs/core';
import Split from 'react-split';
import Editor from '../../components/aleoj/editor';
import Description from '../../components/aleoj/description';
import Submission from '../../components/aleoj/submission';
import Problems from '../../components/aleoj/problems';
import About from '../../components/aleoj/about';
import Header from '../../components/aleoj/header';
import Footer from '../../components/aleoj/footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../config';

const defaultCodeTmp = `#include <iostream>
using namespace std;

int main() {
  return 0;
}`;

function AleojView(props) {
    const [tabId, setTabId] = useState("des");
    const [showDrawer, toggleProblems] = useState(false);
    const [showAbout, toggleAbout] = useState(false);
    const [isSubmiting, setSubmiting] = useState(false);
    const [result, setResult] = useState({});
    const [code, setCode] = useState(defaultCodeTmp);
    let { id } = useParams();
    const problemId = id || 27;

    const onProblemsClick = () => {
        toggleProblems(true);
    }
    const onSubmitClick = async () => {
        setSubmiting(true);
        try {
            const payload = {
                problem_id: problemId,
                language: 'c++',
                author: 'guest',
                code
            };
            const result = await axios.post(
                `${API_URL}/solutions`,
                payload
            );
            setResult(result.data);
        } catch (e) {
            const { error } = e.response.data;
            setResult({
                status: 'ERR',
                error
            });
        } finally {
            setTabId("sub");
            setSubmiting(false);
        }
    }

    const onChooseProblem = (id) => {
        if (parseInt(id) === parseInt(problemId)) {
            return;
        } else {
            setTabId('des');
            setCode(defaultCodeTmp);
        }
    }

    return <div className="aleoj-container">
        <About toggle={toggleAbout} show={showAbout} />
        <Header toggleAbout={toggleAbout} />
        <Split className="split aleoj-main" gutterSize={5} minSize={400}>
            <div className="aleoj-preview">
                <Tabs
                    renderActiveTabPanelOnly={true}
                    animate={true}
                    selectedTabId={tabId}
                    onChange={(id) => setTabId(id)}
                >
                    <Tab id="des" title="Description" panel={<Description problemId={problemId} />} panelClassName="aleoj-preview-panel-description" />
                    <Tab id="sub" title="Submissions" panel={<Submission result={result} problemId={problemId} />} panelClassName="aleoj-preview-panel-submission" />
                </Tabs>
            </div>
            <div className="aleoj-editor">
                <Editor code={code} setCode={setCode} />
            </div>
        </Split>

        <Footer onProblemsClick={onProblemsClick} onSubmitClick={onSubmitClick} isSubmiting={isSubmiting} />

        <Drawer
            className="aleoj-problems-drawer bp3-dark"
            isOpen={showDrawer}
            position="left"
            canOutsideClickClose={true}
            onClose={() => toggleProblems(false)}
        >
            <Problems close={() => toggleProblems(false)}  onChoose={onChooseProblem} />
        </Drawer>
    </div>;
}


export default AleojView;