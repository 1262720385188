import React from 'react';
import { Navbar, Alignment, Button } from '@blueprintjs/core';

function Header(props) {
    return <Navbar className="bp3-dark aleoj-navbar">
        <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading className="bp3-monospace-text">ALEOJ</Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
            <Navbar.Divider />
            <Button className="bp3-minimal" icon="help" onClick={() => props.toggleAbout(true)} />
        </Navbar.Group>
    </Navbar>;
}

export default Header;